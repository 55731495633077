/* @font-face {
  font-family: Gotham;
  src: url("GothamBook.ttf");
  src: url("GothamMedium.ttf");
} */

@import url("https://fonts.googleapis.com/css?family=Poppins:wght@300&display=swap");

* {
  font-family: "Poppins";
}

body {
  margin: 0 10px;
  overflow-x: hidden;
}

.header {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.little-image {
  /* width: 142px; */
  height: 76px;
  /* flex: 1; */
  /* object-fit: cover; */
}

.student-header {
  padding: 7px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 22px;
  text-align: center;
  flex: 7;
  border-bottom: 3px solid black;
  border-image: linear-gradient(
      to right,
      rgb(226, 44, 29) 25%,
      rgba(245, 174, 48, 255) 25%,
      rgba(245, 174, 48, 255) 50%,
      rgba(34, 137, 203, 255) 50%,
      rgba(34, 137, 203, 255) 75%,
      rgba(42, 149, 69, 255) 75%
    )
    5;
}

main {
  display: flex;
  gap: 4%;
  justify-content: space-between;
  /* height: 90%; */
  width: 100%;
}

main .normal-data {
  width: 48%;
}

main .location-data {
  width: 48%;
}

input {
  display: block;
  border: none;
  border-bottom: 1px solid lightgray;
}

input:focus {
  outline: none;
}

h4 {
  /* margin: 10px 0; */
  margin: 1% 0;
  letter-spacing: 0.5px;
  font-size: 17px;
}

select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#school-details {
  padding-left: 20px;
  color: gray;
}

.dropdown-icon {
  position: absolute;
  top: 7.5px;
  left: 300px;
  font-size: 20px;
}

.required {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  display: inline-block;
  margin-top: 13px;
}

.required:after {
  content: "*";
  color: red;
}

.tags {
  /* border-radius: 15px; */
  /* border: 1px solid lightgray; */
  /* width: 330px; */
  width: 100%;
  height: 25px;
  padding-left: 5px;
  /* margin-bottom: %; */
  text-transform: capitalize;
  /* text-indent: 10px; */
}

input[type="email"] {
  text-transform: lowercase;
}

form {
  height: calc(100vh - 120px);
}

.tags::placeholder {
  /* padding-left: 10px; */
  font-size: 12px;
  letter-spacing: 0.5px;
  /* padding-bottom: -5px; */
  text-transform: capitalize;
}

.sub-header-title {
  text-transform: uppercase;
  position: relative;
  /* margin-bottom: 15px; */
}

.sub-header-title::after {
  content: "";
  width: 78px;
  height: 2.5px;
  background: rgb(214, 76, 76);
  position: absolute;
  bottom: -4px;
  left: 1px;
}

.nearbyLocations {
  position: absolute;
  background-color: white;
  width: 330px;
  border: 1px solid grey;
  border-top: none;
  border-bottom: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 13px;
  font-weight: normal;
  z-index: 5;
}

.nearbyLocations p {
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  cursor: pointer;
}

.nearbyLocations p:hover {
  background-color: rgb(233, 233, 233);
}

.nearbyLocations p:last-child {
  border-radius: 10px;
}

.button {
  text-transform: uppercase;
  border-radius: 15px;
  font-size: 11px;
  padding: 10px 38px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 30px;
}

.buttons {
  /* position: relative; */
}

.submit {
  color: white;
  margin-top: 20px;
  border: 1px solid rgb(15, 153, 15);
  background-color: rgb(19, 170, 19);
}

.submit:hover {
  background-color: green;
}

.reset {
  color: blue;
  margin-top: 20px;
  border: 1px solid rgb(11, 116, 158);
  background-color: white;
  margin-left: 20px;
}

.reset:hover {
  color: white;
  background-color: rgb(11, 116, 158);
}

.sub-container {
  display: flex;
  gap: 4%;
}

.sub-container .inputContainers {
  width: 48%;
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
}

.copyright .lFirst {
  color: green;
}

.copyright .i {
  color: teal;
}

.copyright .tFirst {
  color: red;
}

.copyright .tSecond {
  color: rgb(223, 188, 31);
}

.copyright .lSecond {
  color: teal;
}

.copyright .e {
  color: red;
}

.copyright .year {
  font-weight: normal;
}

.error {
  font-size: 10.5px;
  color: red;
  /* font-weight: bold; */
  /* margin-left: 15px; */
  padding-left: 5px;
  margin-top: 1px;
}

.address {
  width: 600px;
}

.location {
  position: relative;
}

.location-icon {
  position: absolute;
  right: 10px;
  top: 7.5px;
  font-size: 17px;
  color: gray;
}

#student-photo {
  opacity: 0;
  position: absolute;
  margin-top: -15px;
  margin-left: -15px;
  cursor: pointer;
}

.address-line {
  /* width: 680px; */
  width: 100%;
}

.file-upload {
  /* border-radius: 15px; */
  border: none;
  border-bottom: 1px solid lightgray;
  /* width: 330px; */
  width: 100%;
  height: 25px;
  padding-left: 5px;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding-top: 4px;
  color: gray;
  position: relative;
}

.file-upload .logo {
  position: absolute;
  right: 25px;
  font-size: 14px;
}

.file-upload .eye {
  right: 0px;
  cursor: pointer;
}

.file-upload .eye:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  form {
    height: calc(100% - 120px);
  }

  main {
    display: block;
    height: 100%;
  }

  main .normal-data {
    width: 100%;
  }

  main .location-data {
    width: 100%;
  }

  .sub-container {
    display: block;
  }

  .sub-container .inputContainers {
    width: 100%;
  }

  .address-line {
    width: 100%;
  }

  body {
    margin-left: 5%;
  }

  .address,
  .location {
    width: 330px;
  }

  .imageCrop-footer .sub-footer {
    display: flex;
    flex-direction: column;
  }

  .imageCrop-footer .sub-footer img {
    margin-left: 0;
  }

  .little-image {
    width: 117px;
    height: 60px;
    /* flex: 1; */
    object-fit: cover;
  }
}

.modalContainerForImageCrop {
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalBackgroundForImageCrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
}

.button {
  margin-bottom: 10px;
}

.imageCrop-footer {
  display: flex;
  justify-content: space-between;
}

.imageCrop-submit {
  align-self: flex-end;
  padding: 15px;
  border-radius: 10px;
  color: white;
  background-color: rgb(12, 118, 160);
  border: skyblue;
  cursor: pointer;
}

.imageCrop-footer .text {
  align-self: center;
}

.imageCrop-footer .image {
  width: 100px;
  height: 100px;
  /* margin-left: 50%; */
  margin-top: 10px;
}

.imageCrop-footer .sub-footer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
