* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#jay {
  height: 73.5%;
  position: relative;
}

#map {
  /* height: 100%; */
  position: relative;
  height: 100%;
  /* max-width: 606px; */
  width: 99%;
  margin-top: 10px;
  border-radius: 10px;
  /* border: 4px solid black; */
  /* border-image: linear-gradient(
      rgba(217, 34, 41, 255) 0%,
      rgba(217, 34, 41, 255) 20%,
      rgb(223, 188, 31) 20%,
      rgb(223, 188, 31) 50%,
      teal 50%,
      teal 75%,
      green 75%
    )
    1; */
}

#jay::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -0.7%;
  top: -1.25%;
  width: 100.45%;
  height: 102.3%;
  border-radius: 10px;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(rgb(226, 44, 29), rgb(226, 44, 29)),
    linear-gradient(rgba(245, 174, 48, 255), rgba(245, 174, 48, 255)),
    linear-gradient(rgba(34, 137, 203, 255), rgba(34, 137, 203, 255));
}

#jay::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0px;
  top: 0px;
  width: calc(100% - 7px);
  height: calc(100%);
  background: white;
  border-radius: 10px;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  /* font-family: Roboto; */
  padding: 0;
}

.stop-details__pickup,
.stop-details__drop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

#pac-container {
  /* padding-bottom: 12px; */
  /* margin-right: 12px; */
  /* display: flex; */
  /* gap: 25px; */
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: 300;
}

#pac-input1 {
  text-overflow: ellipsis;
  /* width: 600px; */
  width: 100%;
}

#pac-input2 {
  text-overflow: ellipsis;
  /* width: 600px; */
  width: 100%;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

@media screen and (max-width: 768px) {
  #pac-input1 {
    /* width: 330px; */
  }

  #pac-input2 {
    /* width: 330px; */
  }

  #jay {
    width: 336px;
    height: 250px;
  }

  #map {
    width: 330px;
    height: 250px;
  }
}
